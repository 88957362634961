/**
 + HTML & body
 */
$header-height: 132px;
html {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
body {
  overflow-x: hidden;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-right: 0 !important;
}
main#main {
  flex-grow: 1;
  border: none !important;
}

header {
  height: $header-height;
  .fixed-top {
    padding-right: 0 !important;
  }
  .nav-link {
    border: 2px solid transparent;
    border-radius: $border-radius;
    &:focus {
      color: $primary;
    }
    @include media-breakpoint-up(lg) {
      &.active {
        border-color: $primary;
      }
    }
  }
  .navbar-brand {
    max-width: 70%;
  }
  .offcanvas-header {
    padding-top: 2rem;
  }
}


footer {
  .contactlink {
    --bs-link-color-rgb: rgb($white);
    --bs-link-color-rgb: rgb($body-color);
    --contactlink-padding-right: 2rem;
    --contactlink-icon-gap: 1rem;
    text-decoration: none;
    padding: 0.5rem var(--contactlink-padding-right) 0.5rem 0;
    white-space: nowrap;
    display: inline-block;
    svg {
      margin-right: var(--contactlink-icon-gap);
    }
    @include media-breakpoint-up(md) {
      font-size: $h4-font-size
    }
    @include media-breakpoint-down(sm) {
      --contactlink-padding-x: 1rem;
      --contactlink-icon-gap: 0.5rem;
    }
  }
  .nav {
    --bs-nav-link-padding-x: 0.75rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-size: 0.875rem;
    --bs-nav-link-color: $white;
    --bs-nav-link-hover-color: $body-color;
    --bs-nav-link-disabled-color: var(--bs-secondary-color);
  }
}