#introSection {
  position: relative;
  .introimg {
    width: 100%;
    height: auto;
  }
  .stoerer {
    position: absolute;
    right: 50px;
    top: 10%;
  }
  @include media-breakpoint-up(md) {
    position: relative;
    .intro-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    .introimg {
      width: calc(50% - #{$grid-gutter-width}/2);
    }
    .introoverlay {
      position: absolute;
      height: 100%;
      width: 40%;
      right: calc(50% + #{$grid-gutter-width}/2);
      top: 0;
      background: linear-gradient(90deg, rgba($light,0) 10%, rgba($light,1) 100%);
    }
    .stoerer {
      position: absolute;
      right: 60%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}



#lernkonzeptSection {
  .accordion > .accordion-item:not(:last-child) {
    border-bottom: 2px solid $light;
  }
  .accordion-button {
    font-family: $font-family-base;
    font-weight: 700;
  }
  .accordion-body {
    padding-top: 0;
  }
}



#demoSection {
  background-color: $light;
  background-image: url('/assets/media/bg-video-section.jpg');
  background-position: center right;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  @include media-breakpoint-down(md) {
    background-size: cover;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: $light;
      opacity: 0.7;
    }
    > div {
      z-index: 2;
      position: relative;
    }
  }
}
#demoVideoModal {
  .modal-content {
    background: transparent;
    border-radius: 0;
  }
}



#anmeldungSection {
  .stoerer {
    --box-padding: 1.5rem;
    margin-left: auto;
    margin-right: calc(var(--box-padding)*(-1));
    margin-top: calc(var(--stoerer-width)*(-1) + var(--box-padding));
    @include media-breakpoint-up(xl) {
      --box-padding: 2rem;
    }
    @include media-breakpoint-only(md) {
      --stoerer-width: 160px;
      margin-top: -80px;
      .stoerer-text-big {
        font-size: 1rem;
      }
    }
    @include media-breakpoint-down(sm) {
      --stoerer-width: 160px;
    }
  }
}


#kontaktSection {
  --portrait-margin: 120px;
  padding-top: var(--portrait-margin);
  .portrait {
    margin-bottom: 0;
    margin-top: calc( var(--portrait-margin)*(-1) );
    position: relative;
    img {
      z-index: 2;
      position: relative;
    }
    figcaption {
      padding: 6px 26px 6px 48px;
      background-color: $white;
      border-radius: 0 $border-radius $border-radius 0;
      color: $primary;
      font-family: "Bradley Hand";
      font-size: $h4-font-size;
      font-weight: bold;
      position: absolute;
      left: 240px;
      top: 210px;
    }
  }
  @include media-breakpoint-down(md) {
    --portrait-margin: 0;
  }
  @include media-breakpoint-down(sm) {
    .portrait {
      img {
        width: 50%;
      }
      figcaption {
        left: 20%;
        top: auto;
        padding-left: 32%;
        bottom: 12%;
      }
    }
  }
}


section.imprint {
  h1,.h1 {
    color: $primary;
  }
  --bs-heading-color: $body-color;
  h2,.h2 {
    font-size: $h4-font-size
  }
  h3,.h3 {
    font-size: $h5-font-size
  }
  h4,.h4 {
    font-size: $h6-font-size
  }
  h5,.h5 {
    font-size: $h6-font-size
  }
  h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 2rem;
  }
}