.stoerer {
  --stoerer-width: 190px;
  background: $primary;
  color: $white;
  padding: 16px;
  border-radius: 50%;
  width: var(--stoerer-width);
  height: var(--stoerer-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  rotate: -6deg;
  .stoerer-text-big {
    font-size: 1.25rem;
    font-family: $headings-font-family;
    font-weight: bold;
  }
  hr {
    border-color: $white;
    width: 100%;
    background: #fff;
    opacity: 1;
    margin: 0.5rem 0;
  }
  @include media-breakpoint-down(sm) {
    --stoerer-width: 150px;
    font-size: 0.875rem;
    .stoerer-text-big {
      font-size: 1rem;
    }
  }
}


.hja-close {
  background: transparent;
  border: none;
  display: flex;
  gap: 1rem;
  color: #fff;
  margin-left: auto;
  align-items: center;
  padding-bottom: 0.75rem;
  &:hover {
    color: $body-color
  }
}

.btn-outline-primary {
  --bs-btn-hover-color: #{white};
  --bs-btn-active-color: #{white};
}