@font-face {
  font-family: 'Mensura';
  font-weight: 700;
  src: url('/assets/fonts/mensura/bold.eot');
  src: url('/assets/fonts/mensura/bold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/mensura/bold.woff2') format('woff2'),
  url('/assets/fonts/mensura/bold.woff') format('woff'),
  url('/assets/fonts/mensura/bold.ttf') format('truetype');
}


@font-face {
  font-family: 'Mensura';
  font-weight: 400;
  src: url('/assets/fonts/mensura/regular.eot');
  src: url('/assets/fonts/mensura/regular.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/mensura/regular.woff2') format('woff2'),
  url('/assets/fonts/mensura/regular.woff') format('woff'),
  url('/assets/fonts/mensura/regular.ttf') format('truetype');
}




@font-face {
  font-family: "Bradley Hand";
  src: url("/assets/fonts/badley-hand/regular.eot");
  src: url("/assets/fonts/badley-hand/regular.eot")format("embedded-opentype"),
  url("/assets/fonts/badley-hand/regular.woff2")format("woff2"),
  url("/assets/fonts/badley-hand/regular.woff")format("woff"),
  url("/assets/fonts/badley-hand/regular.ttf")format("truetype"),
  url("/assets/fonts/badley-hand/regular.svg")format("svg");
}



/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/roboto/roboto-v30-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('/assets/fonts/roboto/roboto-v30-latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* roboto-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/roboto/roboto-v30-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('/assets/fonts/roboto/roboto-v30-latin-italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* roboto-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/roboto/roboto-v30-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('/assets/fonts/roboto/roboto-v30-latin-700.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* roboto-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/roboto/roboto-v30-latin-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('/assets/fonts/roboto/roboto-v30-latin-700italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}